<template>
  <div>
    <el-button size="mini" type="success" @click="to">编辑</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        copyType: this.data.copyType,
        copyMaxLots: this.data.copyMaxLots,
        copyMaxNum: this.data.copyMaxNum,
        copyStatus: this.data.copyStatus && this.data.copyStatus.toString(),
        beginDate: this.data.beginDate,
        expireDate: this.data.expireDate,
        status: this.data.status.toString(),
        copyDirection: this.data.copyDirection,
      }
      _this.dialog = true;
      if(this.data.copyType == 3){
        _this.samrkCopyValue = this.data.copyValue;
      }else if(this.data.copyType == 2){
        _this.rateCopyValue = this.data.copyValue;
      }else {
        _this.numberCopyValue = this.data.copyValue;
      }
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
