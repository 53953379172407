<template>
  <el-dialog title="订阅流水" :visible.sync="dialogVisible" width="70%" append-to-body>
    <el-table
      :data="listData"
      stripe
      style="width: 100%;"
    >
      <el-table-column prop="transId" label="编号" width="80"/>
      <el-table-column prop="signalType" label="账户类型"/>
      <el-table-column prop="priceType" label="收费方式" width="150">
        <template slot-scope="scope">
           <span class="span-item" >{{scope.row.priceType}}</span>
           <span v-if="scope.row.signalType=='MAM'">
             <span v-if="scope.row.extraType==0" class="span-item">表现费：无</span>
            <span v-else class="span-item">
              <el-tooltip class="item" effect="dark" placement="top-start">
                <div slot="content">
                   <div v-for="(item,index) in scope.row.extraType"  :key="index">
                      {{item}} <br/>
                    </div>
                </div>
                <span class="span-item" >表现费：...</span>
              </el-tooltip>
            </span>
           </span>

        </template>
      </el-table-column>
      <el-table-column prop="subPeriod" label="订阅周期"/>
      <el-table-column label="到期时间" width="350px">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.expireDate)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="transStatus" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row.transStatus)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" width="350px">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.transDate)}}</span>
        </template>
      </el-table-column>

    </el-table>
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </el-dialog>
</template>

<script>

  import { parseTime } from "@/utils/index";
  export default {
    name: 'subscribeList',
    props: {
      listData: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        page: 0,
        size: 10,
        total: 0,
        dialogVisible: false
      }
    },
    methods: {
      parseTime,
      dialogSizeChange(){
        this.$parent.fetchListData();
      },
      dialogPageChange(){
        this.$parent.fetchListData();
      },
      getStatus(status){
        switch (status) {
          case 0:
            return '无效';
          case 1:
            return '有效';
          case 2:
            return '过期';
          case 3:
            return '取消订阅';
        }
      }
    }
  }
</script>

<style scoped>
  .span-item{
    display: block;
  }
</style>
