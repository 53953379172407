<template>
  <el-dialog title="订阅流水" :visible.sync="dialogVisible" width="70%" append-to-body>
    <el-table
      :data="listData"
      stripe
      style="width: 100%;"
    >
      <el-table-column prop="paymentId" label="流水号" width="80"/>
      <el-table-column prop="priceType" label="支付类型"/>
      <el-table-column prop="price" label="支付金额">
        <template slot-scope="scope">
          <span v-if="scope.row.priceType=='订阅费'">{{scope.row.price}}</span>
          <el-tooltip v-else class="item" effect="dark" :content="scope.row.priceDetail" placement="top-start">
             <span>{{scope.row.price}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="支付状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row.status)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reason" label="支付说明" />
      <el-table-column label="支付时间" width="350px">
        <template slot-scope="scope">
          <span>{{parseTime(scope.row.transDate)}}</span>
        </template>
      </el-table-column>

    </el-table>
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </el-dialog>
</template>

<script>

  import { parseTime } from "@/utils/index";
  export default {
    name: 'subscribePayList',
    props: {
      listData: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    data() {
      return {
        page: 0,
        size: 10,
        total: 0,
        dialogVisible: false
      }
    },
    methods: {
      parseTime,
      dialogSizeChange(){
        this.$parent.fetchPayListData();
      },
      dialogPageChange(){
        this.$parent.fetchPayListData();
      },
      getStatus(status){
        switch (status) {
          case 0:
            return '处理中';
          case 1:
            return '成功';
          case 2:
            return '失败';
          case 3:
            return '待处理';
        }
      }
    }
  }
</script>

<style scoped>
  .span-item{
    display: block;
  }
</style>
