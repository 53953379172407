<template>
  <div class="head-container">
    <!-- 搜索 -->
    <account-type
            ref="accountType"
            @toQuery="toQuery"
    ></account-type>
    <el-select
            v-model="query.status"
            clearable
            placeholder="订阅状态"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option v-for="item in queryStatusOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>
    <el-input v-model="query.signalInfo" clearable placeholder="信号源昵称/MT4" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>

    <el-input v-model="query.copyInfo" clearable placeholder="订阅者昵称/MT4" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>

    &nbsp;&nbsp;
    <div class="filter-item">
      订阅时间:
      <el-date-picker style="width: 150px;" v-model="query.startTime" type="date" placeholder="开始时间"></el-date-picker>
      至
      <el-date-picker style="width: 150px;" v-model="query.endTime" type="date" placeholder="结束时间"></el-date-picker>
    </div>
    &nbsp;&nbsp;
    <el-button v-if="checkPermission(['ADMIN','SUBSCRIPTION_ALL','SUBSCRIPTION_SELECT'])" class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button
            type="primary"
            class="filter-item"
            size="mini"
            @click="toClearInit"
            icon="el-icon-close"
    >清空</el-button>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import accountType from '@/components/copy/accountType'
import { parseTime } from '@/utils/index'
export default {
  components: {
    accountType
  },
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      downloadLoading: false,
      queryStatusOptions: [
        { key: '0', display_name: '有效' },
        { key: '1', display_name: '已结束' },
        { key: '2', display_name: '取消订阅'},
        { key: '3', display_name: '未生效' },
        { key: '4', display_name: '结算中' }
      ],
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    },
    toClearInit(){
      this.$emit('clear');
    },
  }
}
</script>
