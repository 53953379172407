<template>
    <div class="app-container">
        <eHeader ref="header" :query="query" @toQuery="toQuery" @clear="clearHandle"/>
        <!--表格渲染-->
        <el-table v-loading="loading" :data="data" stripe size="small" style="width: 100%;">
            <el-table-column prop="id" label="ID" width="70"/>
            <el-table-column prop="accountType" label="账户类型"/>
            <el-table-column prop="login" label="订阅者" width="130">
                <template slot-scope="scope">
                    <span class="span-item">{{scope.row.copyAlias}}</span>
                    <span class="span-item">{{scope.row.copyLogin}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="signalLogin" label="信号源" width="130">
                <template slot-scope="scope">
                    <span class="span-item">{{scope.row.signalAlias}}</span>
                    <span class="span-item">{{scope.row.signalLogin	}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="copyType" label="跟随策略">
                <template slot-scope="scope">
                    <span v-if="!scope.row.copyValue && !scope.row.copyType">-</span>
                    <template v-else>
                        <span class="span-item">{{getType(scope.row.copyType)}}</span>
                        <span>{{scope.row.copyValue}}</span>
                        <span>{{scope.row.copyType==1 ? '手': '倍'}}</span>
                    </template>
                </template>
            </el-table-column>
            <el-table-column prop="copyMaxLots" label="最大持仓手数/笔数" width="100">
                <template slot-scope="scope">
                    <span>{{scope.row.copyMaxLots || '-'}}/{{scope.row.copyMaxNum || '-'}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="copyStatus" label="跟单状态">
                <template slot-scope="scope">
                    <span>{{scope.row.copyStatus==0?'暂停':'正常'}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="priceType" label="费用类型"/>
            <el-table-column prop="isRenew" label="自动续订">
                <template slot-scope="scope">
                    <span v-if="scope.row.isRenew==0">否</span>
                    <span v-if="scope.row.isRenew==1">是</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="订阅状态">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==0">有效</span>
                    <span v-if="scope.row.status==1">已结束</span>
                    <span v-if="scope.row.status==2">取消订阅</span>
                    <span v-if="scope.row.status==3">未生效</span>
                    <span v-if="scope.row.status==4">结算中</span>
                </template>
            </el-table-column>
            <el-table-column prop="subscriptionTime" label="订阅时间" width="150px" />
            <el-table-column prop="endDate" label="结束时间" width="250px" />
            <el-table-column label="操作" width="280px" align="center">
                <template slot-scope="scope">
                    <edit v-if="checkPermission(['ADMIN','SUBSCRIPTION_ALL','SUBSCRIPTION_EDIT'])" :data="scope.row"
                          :sup_this="sup_this"/>
                    <el-dropdown
                            size="mini"
                            v-if="getSomeOne(scope.row) && !scope.row.custId && checkPermission(['ADMIN','COMMISSIONRULES_ALL'])"
                    >
                        <el-button size="mini" type="success">
                            更多操作
                            <i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                    class="dropdown-item"
                                    v-if="checkPermission(['ADMIN','SUBSCRIPTION_TRANS_ALL','SUBSCRIPTION_TRANS_SELECT'])"
                            >
                <span
                        @click="openDetail(scope.row)"
                >{{'订阅流水'}}</span>
                            </el-dropdown-item>
                            <el-dropdown-item
                                    class="dropdown-item"
                                    v-if="checkPermission(['ADMIN','SUBSCRIPTION_PAYMENT_TRANS_ALL','SUBSCRIPTION_PAYMENT_TRANS_SELECT'])"
                            >
                <span
                        @click="openPayDetail(scope.row)"
                >{{'支付流水'}}</span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
        <!--分页组件-->
        <public-pagination
                :total-page="total"
                :current-page="page"
                @pageChange="pageChange"
                @sizeChange="sizeChange"
        ></public-pagination>

        <subscribe-list ref="list" :list-data="listData"></subscribe-list>
        <subscribePayList ref="paylist" :list-data="paylistData"></subscribePayList>
    </div>
</template>

<script>
  import { querySubTransBySubId, querySubPayTransBySubId } from '@/api/copy/srCommunitySubscription'
  import subscribeList from '@/components/copy/sub/subscribeList'
  import subscribePayList from '@/components/copy/sub/subscribePayList'
  import checkPermission from '@/utils/permission'
  import initData from '@/mixins/initData'
  import { parseTime } from '@/utils/index'
  import eHeader from '@/components/copy/sub/header'
  import edit from '@/components/copy/sub/edit'

  export default {
    name: 'sub',
    components: { eHeader, edit, subscribeList, subscribePayList },
    mixins: [initData],
    data() {
      return {
        sup_this: this,
        dialogVisible: false,
        subscriptionTransData: [],
        listParams: {
          login: '',
          signalLogin: ''
        },
        selectedID: '',
        listData: [],
        paylistData: []
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.init()
      })
    },
    methods: {
      parseTime,
      checkPermission,
      getType(copyType) {
        switch (copyType) {
          case 3:
            return '智能比例'
          case 1:
            return '固定数量'
          case 2:
            return '固定比例'
        }
      },
      beforeInit() {
        this.url = 'crm/querySubscriptionList'
        const sort = 'id,desc'
        const query = this.query
        this.params = {
          page: this.page,
          size: this.size,
          sort: sort
        }
        const status = query.status

        const startTime = query.startTime
        const endTime = query.endTime
        if (status !== '' && status !== null) {
          this.params['status'] = status
        }
        if (this.query.signalInfo !== '' && this.query.signalInfo !== null) {
          this.params['signalInfo'] = this.query.signalInfo;
        }
        if (this.query.copyInfo !== '' && this.query.copyInfo !== null) {
          this.params['copyInfo'] = this.query.copyInfo;
        }
        if (startTime !== undefined && startTime !== '' && startTime !== null) {
          this.params['startTime'] = parseTime(startTime)
        }
        if (endTime !== undefined && endTime !== '' && endTime !== null) {
          this.params['endTime'] = parseTime(endTime)
        }
        if (this.$refs.header.$refs.accountType.businessType) {
          this.params['businessType'] = this.$refs.header.$refs.accountType.businessType
        }
        return true
      },
      openDetail(row) {
        this.listParams = {
          copyLogin: row.copyLogin,
          signalLogin: row.signalLogin
        }
        this.selectedID = row.id,
          querySubTransBySubId({
            subscriptionId: this.selectedID,
            sort: 'id,desc',
            page: this.$refs.list.page,
            size: this.$refs.list.size
          }).then(res => {
            this.listData = res.content
            this.$refs.list.total = res.totalElements
            this.$refs.list.dialogVisible = true
          }).catch(err => {
          })
      },
      fetchListData() {
        querySubTransBySubId({
          subscriptionId: this.selectedID,
          sort: 'id,desc',
          page: this.$refs.list.page,
          size: this.$refs.list.size
        }).then(res => {
          this.listData = res.content
          this.$refs.list.total = res.totalElements
        }).catch(err => {
        })
      },
      openPayDetail(row) {
        this.listParams = {
          copyLogin: row.copyLogin,
          signalLogin: row.signalLogin
        }
        this.selectedID = row.id,
          querySubPayTransBySubId({
            subscriptionId: this.selectedID,
            sort: 'id,desc',
            page: this.$refs.paylist.page,
            size: this.$refs.paylist.size
          }).then(res => {
            this.paylistData = res.content
            this.$refs.paylist.total = res.totalElements
            this.$refs.paylist.dialogVisible = true
          }).catch(err => {
          })
      },
      fetchPayListData() {
        querySubPayTransBySubId({
          subscriptionId: this.selectedID,
          sort: 'id,desc',
          page: this.$refs.paylist.page,
          size: this.$refs.paylist.size
        }).then(res => {
          this.paylistData = res.content
          this.$refs.paylist.total = res.totalElements
        }).catch(err => {
        })
      },
      toClearInit() {
        this.query = {}
        if (this.$refs.header.$refs.accountType.businessType) {
          this.$refs.header.$refs.accountType.businessType = ''
        }
        this.init();
      },
      clearHandle(){
        this.toClearInit();
      },
      getSomeOne(row){
        return checkPermission(['ADMIN','SUBSCRIPTION_TRANS_ALL','SUBSCRIPTION_TRANS_SELECT']) || checkPermission(['ADMIN','SUBSCRIPTION_PAYMENT_TRANS_ALL','SUBSCRIPTION_PAYMENT_TRANS_SELECT']);
      }
    }
  }
</script>

<style scoped>
    .span-item {
        display: block;
    }
</style>
